import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Agreement = ({ location }) => {
  return (
    <Layout lang="en-US">
      <Seo
        pagetitle="Terms of Use"
        pagepath={location.pathname}
        pagedesc="Terms of Use | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
      />
      <Breadcrumb title="Terms of Use" />
      <main className="policy">
        <section className="policy__section l-container">
          <h1 className="policy__title">Terms of Use</h1>
          <p className="policy__text">
            These terms of use(hereinafter referred to as the "Terms") set forth
            the basic matters necessary for receiving "PARKLoT", "PARKLoT Lite",
            "Coupon", "QR Code Lottery" and "Manga Advertisement"(hereinafter
            referred to as the "Service") operated by X - HACK Co.The Terms of
            Use shall apply to all users of the Services.All registered
            users(hereinafter referred to as "Users") are required to comply
            with these Terms of Use.Registered users(hereinafter referred to as
            "Users") are requested to use the Service in accordance with these
            Terms of Use.
          </p>
          <h2 className="policy__title2">Article 1 (Application)</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              These Terms of Use shall apply to all relationships related to the
              use of the Service between the user and the Company.
            </li>
            <li className="policy__item">
              In addition to the Terms of Use, the Company may stipulate various
              rules and regulations concerning the use of the Service
              (hereinafter referred to as "Individual Regulations"). These
              individual provisions, regardless of their name, shall constitute
              a part of these Terms and Conditions.
            </li>
          </ol>
          <h2 className="policy__title2">Article 2 (Registration for Use)</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              The registration for this service is completed when a prospective
              registrant agrees to the Terms of Use and applies for registration
              for use of the service through a method determined by the Company,
              and when the Company approves the application.
            </li>
            <li className="policy__item">
              The Company may not approve an application for registration of use
              if it determines that the applicant has any of the following
              reasons, and the Company shall not be obligated to disclose any
              reasons for such denial.
              <ul className="policy__list">
                <li className="policy__item">
                  If false information is reported when applying for user
                  registration
                </li>
                <li className="policy__item">
                  If the application is from a person who has violated these
                  Terms and Conditions
                </li>
                <li className="policy__item">
                  Other cases in which the Company deems the registration of use
                  to be inappropriate
                </li>
              </ul>
            </li>
          </ol>
          <h2 className="policy__title2">
            Article 3 (Management of User ID and Password)
          </h2>
          <ol className="policy__olist">
            <li className="policy__item">
              The User shall properly manage his/her user ID and password for
              the Service at his/her own responsibility.
            </li>
            <li className="policy__item">
              The User may not, under any circumstances, transfer or lend
              his/her user ID and password to a third party, or share them with
              a third party. When a user logs in with the same combination of
              user ID and password as the registered information, we consider
              the use of the service to be by the user who has registered that
              user ID.
            </li>
            <li className="policy__item">
              The Company shall not be liable for any damage caused by the use
              of user IDs and passwords by third parties, except in the case of
              willful misconduct or gross negligence on the part of the Company.
            </li>
          </ol>
          <h2 className="policy__title2">
            Article 4 (Usage Fees and Payment Methods)
          </h2>
          <ol className="policy__olist">
            <li className="policy__item">
              As compensation for the paid portion of the Service, the User
              shall pay the usage fee separately determined by the Company and
              displayed on the Website in a manner designated by the Company.
            </li>
            <li className="policy__item">
              In the event the User is late in paying the usage fee, the User
              shall pay a late fee at the rate of 14.6% per annum.
            </li>
            <li className="policy__item">
              In the event that a user cancels the subscription in the middle of
              the term of use or for any other reason, we shall not be obligated
              to refund the usage fee paid by the user.
            </li>
          </ol>
          <h2 className="policy__title2">Article 5 (Prohibited Matters)</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              Acts that violate laws and regulations or public order and morals
              are prohibited.
            </li>
            <li className="policy__item">
              Any activity related to criminal activity is prohibited.
            </li>
            <li className="policy__item">
              Prohibits activities that are legal abroad but illegal in the
              country.
            </li>
            <li className="policy__item">
              Any infringement of copyrights, trademarks, or other intellectual
              property rights contained in this service, including the contents
              of this service, is prohibited.
            </li>
            <li className="policy__item">
              You are prohibited from disrupting or interfering with the
              functionality of the servers or networks of the Company, other
              users, or other third parties.
            </li>
            <li className="policy__item">
              Commercial use of information obtained through the Service is
              prohibited.
            </li>
            <li className="policy__item">
              Any activity that interferes with the Company's business or the
              operation of the Service, such as placing an excessive burden on
              the server or network system, is prohibited.
            </li>
            <li className="policy__item">
              Unauthorized access or attempts to gain such access are
              prohibited.
            </li>
            <li className="policy__item">
              The collection or accumulation of personal information about other
              users is prohibited.
            </li>
            <li className="policy__item">
              Any use of the Service for any unauthorized purpose is prohibited.
            </li>
            <li className="policy__item">
              Any action that causes disadvantage, damage, or discomfort to
              other users of the Service or other third parties is prohibited.
            </li>
            <li className="policy__item">
              Impersonating another user is prohibited.
            </li>
            <li className="policy__item">
              Advertising, publicity, solicitation, or sales activities on the
              Service that are not authorized by the Company
            </li>
            <li className="policy__item">
              Any activity for the purpose of meeting people of the opposite sex
              whom you have not met is prohibited.
            </li>
            <li className="policy__item">
              We prohibit the act of providing benefits directly or indirectly
              to antisocial forces in relation to our services.
            </li>
            <li className="policy__item">
              Resale of the Service's functions, resources, etc. is prohibited.
              This includes the use of the Service by web production companies,
              agencies, etc. to operate accounts for other companies on their
              behalf. The use of the Service by web production companies, PR
              companies, agencies, etc. to operate multiple unrelated accounts
              of other companies on their behalf using the multi-account
              function is not permitted, as it constitutes resale of the
              Service's functions and resources. As a general rule, only
              accounts owned by the user himself/herself, his/her own company,
              or an affiliated company with which he/she has a capital
              relationship may be used.
            </li>
            <li className="policy__item">
              In principle, changing Twitter IDs during campaign operations is
              prohibited.
            </li>
            <li className="policy__item">
              In principle, changing to a locked account during campaign
              operations is prohibited.
            </li>
            <li className="policy__item">
              If a user engages in any of the prohibited activities on Twitter,
              the Operator reserves the right to restrict use, prevent
              transmission, and take other measures within an appropriate scope.
            </li>
            <li className="policy__item">
              Violating the rules and regulations regarding the use of Twitter
              or other services linked with the Service (hereinafter referred to
              as "Linked Services")
            </li>
            <li className="policy__item">
              Other activities that the Company deems inappropriate are
              prohibited.
            </li>
            <li className="policy__item">
              In the event that the Operator suffers damages as a result of a
              user's conduct as stipulated in each item, the Operator may demand
              compensation for such damages from the user.
            </li>
            <li className="policy__item">
              You may not submit media campaigns that contain grotesque,
              violent, or adult content. Media campaigns containing depictions
              of rape and sexual assault are also not permitted.
            </li>
            <li className="policy__item">
              It is prohibited to include false information in any information
              disclosed to third parties on the Service.
            </li>
          </ol>
          <h2 className="policy__title2">
            Article 6 (Suspension of Provision of the Service, etc.)
          </h2>
          <p className="policy__text">
            We reserve the right to suspend or discontinue all or part of the
            Service without prior notice to users for any of the following
            reasons
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              When performing maintenance inspections or updating of computer
              systems related to this service
            </li>
            <li className="policy__item">
              In the event that provision of this service becomes difficult due
              to force majeure such as earthquake, lightning, fire, power
              outage, or natural disasters.
            </li>
            <li className="policy__item">
              In the event of any trouble, interruption or suspension of service
              provision, suspension of linkage with this service, specification
              changes, etc. to Twitter or other linked services.
            </li>
            <li className="policy__item">
              In the event of computer or communication line outage due to
              accident
            </li>
            <li className="policy__item">
              In any other cases where the Company deems it difficult to provide
              this service.
            </li>
            <li className="policy__item">
              We shall not be liable for any disadvantage or damage incurred by
              users or third parties as a result of the suspension or
              interruption of the provision of the Service, regardless of the
              reason. In addition, the User acknowledges and agrees that even in
              the event of suspension or interruption of the provision of the
              Service by the Company pursuant to this Article, the User shall
              not be entitled to claim any reduction, etc. of the usage fee.
            </li>
          </ol>
          <h2 className="policy__title2">Article 7 (Copyright)</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              Users may only use, post, or edit text, images, videos, and other
              information for which they own the necessary intellectual property
              rights, such as copyrights, or have obtained permission from the
              necessary right holders.
            </li>
            <li className="policy__item">
              The copyright of any text, images, video or other data posted or
              edited by users using the Service (hereinafter referred to as
              "Posted Data") shall be reserved to such users or other existing
              right holders. However, the Company may use (including
              reproduction, copying, modification, sublicensing to third
              parties, and any other use) any text, images, videos, etc. posted
              or edited using the Service. Users shall not exercise their moral
              rights with respect to such use.
            </li>
            <li className="policy__item">
              Except as provided in the main text of Twitter and the preceding
              paragraph, all copyrights and other intellectual property rights
              to the Service and all information related to the Service belong
              to the Company or to the right holders who have authorized the
              Company to use them, and users may not reproduce, transfer, lend,
              translate, modify, reprint, or publicly transmit (including making
              transmittable) any of such information without permission. You may
              not transmit, distribute, publish, commercially use, disassemble,
              decompile, reverse engineer, etc. without permission.
            </li>
          </ol>
          <h2 className="policy__title2">
            Article 8 (Restriction of Use and Cancellation of Registration)
          </h2>
          <p className="policy__text">
            We reserve the right to restrict a user's use of all or part of the
            Service or terminate the user's registration as a user without prior
            notice in any of the following cases
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              Violation of any of the provisions of these Terms and Conditions
            </li>
            <li className="policy__item">
              When it is found that there is a false fact in the registration
              information
            </li>
            <li className="policy__item">
              You have used or attempted to use the Service for any purpose or
              in any manner that may cause damage to us, other Users, or any
              third party.
            </li>
            <li className="policy__item">
              If you interfere with the operation of this service by any means
            </li>
            <li className="policy__item">
              When a bill or check drawn or accepted by the Bank is dishonored,
              or when the Bank is subject to suspension of transactions by a
              clearing house or other similar action.
            </li>
            <li className="policy__item">
              In the event of a petition for seizure, provisional seizure,
              provisional disposition, compulsory execution, or auction, or in
              the event of delinquent payment of taxes and dues
            </li>
            <li className="policy__item">
              When a petition is filed for suspension of payment, insolvency,
              bankruptcy, civil rehabilitation, corporate reorganization, or
              special liquidation proceedings.
            </li>
            <li className="policy__item">
              If you have not used this service for more than 1 year
            </li>
            <li className="policy__item">
              If you have not responded to our inquiries or other communications
              requesting a response for more than 30 days
            </li>
            <li className="policy__item">
              When any of the items of Article 2, Paragraph 2 applies
            </li>
            <li className="policy__item">
              When it is determined that there is information in the campaign
              information published through this service that cannot be clearly
              recognized by a third party.
            </li>
            <li className="policy__item">
              When we determine that campaign information published through the
              Service is insufficient for third parties to understand.
            </li>
            <li className="policy__item">
              In any other cases in which the Company deems the use of the
              Service to be inappropriate.
            </li>
          </ol>
          <p className="policy__text">
            In the event that any of the items of the preceding paragraph
            applies, the User shall naturally lose the benefit of time with
            respect to all debts owed to the Company, and shall immediately
            repay all debts owed to the Company at that time in a lump sum.
          </p>
          <p className="policy__text">
            In no event shall we be liable to you for any damages incurred by
            you as a result of any action taken by us pursuant to this Article.
          </p>
          <h2 className="policy__title2">
            Article 11 (Change of Service Contents, etc.)
          </h2>
          <p className="policy__text">
            The Company may change the contents of the Service or discontinue
            providing the Service without notice to the User, and shall not be
            liable for any damages incurred by the User as a result of such
            changes or discontinuation.
          </p>
          <h2 className="policy__title2">
            Article 12 (Modification of Terms of Use)
          </h2>
          <p className="policy__text">
            We reserve the right to change these Terms of Use at any time
            without notice to you if we deem it necessary. In the event that a
            user starts to use the Service after the Terms of Use have been
            changed, such user will be deemed to have agreed to the changed
            Terms of Use.
          </p>
          <h2 className="policy__title2">
            Article 13 (Handling of Personal Information)
          </h2>
          <p className="policy__text">
            The Company, corporations and individuals shall appropriately handle
            personal information acquired through the use of the Service in
            accordance with the following.
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              The Company shall appropriately handle personal information
              obtained through the use of the Service in accordance with the
              Company's "Privacy Policy".
            </li>
            <li className="policy__item">
              Corporations using the Service shall appropriately handle personal
              information obtained through the use of the Service in accordance
              with the "Privacy Policy" of the linked website, which describes
              the privacy policy clearly indicated by the corporation that
              registered at the time of member registration.
            </li>
            <li className="policy__item">
              Individuals using the Service shall properly handle personal
              information obtained through the use of the Service in accordance
              with our "Privacy Policy.
            </li>
          </ol>
          {/* <h2 className="policy__title2"></h2> */}
          <p className="policy__text">
            Notices or communications between a user and the Company shall be
            made in a manner determined by the Company. Unless a user notifies
            us of a change in his/her contact information in accordance with a
            method separately determined by us, we will assume that the
            currently registered contact information is valid and send notices
            or communications to that contact information, which will be deemed
            to have reached the user at the time of transmission.
          </p>
          <h2 className="policy__title2">
            Article 15 (Governing Law and Jurisdiction)
          </h2>
          <ol className="policy__olist">
            <li className="policy__item">
              These Terms and Conditions shall be construed in accordance with
              the laws of Japan.
            </li>
            <li className="policy__item">
              In the event that a lawsuit becomes necessary in connection with
              the Service, the Osaka District Court shall have exclusive
              jurisdiction as the court of first instance.
            </li>
          </ol>
          <h2 className="policy__title2">Article 16 (Translation)</h2>
          <p className="policy__text">
            The Japanese language is the official language of these Terms and
            Conditions, and translations of these Terms and Conditions into
            other languages are for convenience of reference only and shall have
            no effect.
          </p>
          <br />
          <p className="policy__text">
            QR Code" is a registered trademark of DENSO WAVE INCORPORATED.
          </p>
          <div className="policy__revision">
            <p className="policy__text">March 2, 2023 Revised</p>
            <p className="policy__text">November 18, 2020 Revised</p>
            <p className="policy__text">October 26, 2020 Revised</p>
            <p className="policy__text">October 1, 2020 Establishment</p>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Agreement
